import { Controller } from "@hotwired/stimulus"
import { DirectUpload } from "@rails/activestorage"
import { getMetaValue, findElement, removeElement } from "./utils/utils"
import Dropzone from "dropzone"

export default class extends Controller {
  static targets = ["input"]
  static values = {
    maxFiles: { type: Number, default: 1 },
    maxFileSize: { type: Number, default: 100 },
    existingFiles: Array,
    thumbnailWidth: { type: Number, default: 1000 },
    thumbnailHeight: { type: Number, default: 600 },
    thumbnailMethod: { type: String, default: "contain" },
    addRemoveLinks: { type: Boolean, default: true }
  }

  dropZone: Dropzone

  blobInput: HTMLInputElement

  declare maxFilesValue: Number
  declare readonly hasMaxFilesValue: boolean

  declare maxFilesSizeValue: Number
  declare readonly hasMaxFilesSizeValue: boolean

  declare existingFilesValue: Array<any>
  declare readonly hasExistingFilesValue: boolean

  declare readonly hasInputTarget: boolean
  declare readonly inputTarget: HTMLInputElement
  declare readonly inputTargets: HTMLInputElement[]

  connect() {
    this.dropZone = createDropZone(this)
    this.loadExistingFiles()
    this.bindEvents()
    this.submitButton.disabled = this.isRequired
  }

  inputTargetConnected(fileInput: HTMLInputElement): HTMLInputElement {
    fileInput.style.display = "none"
    return fileInput
  }

  addBlob(blobId: string) {
    if (!this.hasInputTarget) {
      console.error("Missing file input")
      return;
    }
    let input = document.createElement("input")
    input.type = "hidden"
    input.name = this.inputTarget.name
    input.value = blobId
    this.inputTarget.after(input)
    this.inputTarget.disabled = true

    return this.blobInput = input;
  }


  bindEvents() {
    this.dropZone.on("addedfile", (file) => {
      console.log("added", file)
      setTimeout(() => { file.accepted && createDirectUploadController(this, file).start() }, 500)
    })

    this.dropZone.on("removedfile", (file) => {
      console.log("removed", file)
      if (this.blobInput) {
        this.blobInput.value = "purge"
      } else {
        this.addBlob("purge")
      }
    })

    this.dropZone.on("canceled", (file) => {
      console.log("cancelled", file)
      file.xhr?.abort()
    })

    this.dropZone.on("processing", (file) => {
      console.log("processing", file)
      this.submitButton.disabled = true
    })

    this.dropZone.on("queuecomplete", (file) => {
      console.log("queuecomplete", file)
      this.submitButton.disabled = false
    })
  }

  loadExistingFiles() {
    this.existingFilesValue.forEach((existingFile) => {
      addExistingFile(this.dropZone, existingFile)
      this.addBlob(existingFile.blobId)
    })
  }

  get form() { return this.element.closest("form") }
  get inputWrapper() { return this.inputTarget.closest(".input") }
  get submitButton() { return findElement(this.form, "input[type=submit], button[type=submit]") }

  get headers() { return { "X-CSRF-Token": getMetaValue("csrf-token") } }
  get url() { return this.inputTarget.getAttribute("data-direct-upload-url") }
  get isRequired() { return this.inputTarget.classList.contains("required") || this.inputWrapper.classList.contains("required") }

}

class DirectUploadController {
  constructor(source, file) {
    this.directUpload = createDirectUpload(file, source.url, this)
    this.source = source
    this.file = file
    this.xhr = undefined
  }

  directUpload: any
  source: any
  file: any
  xhr: any

  start() {
    // this.hiddenInput = createHiddenInput(this.source.inputTarget)
    this.directUpload.create((error, attributes) => {
      if (error) {
        // removeElement(this.hiddenInput)
        this.emitDropzoneError(error)
      } else {
        this.source.addBlob(attributes.signed_id)
        this.emitDropzoneSuccess()
      }
    })
  }

  // Private


  directUploadWillStoreFileWithXHR(xhr) {
    this.bindProgressEvent(xhr)
    this.emitDropzoneUploading()
  }

  bindProgressEvent(xhr) {
    this.xhr = xhr
    this.xhr.upload.addEventListener("progress", event => this.uploadRequestDidProgress(event))
  }

  uploadRequestDidProgress(event) {
    const element = this.source.element
    const progress = event.loaded / event.total * 100
    findElement(this.file.previewTemplate, ".dz-upload").style.width = `${progress}%`
  }

  emitDropzoneUploading() {
    this.file.status = Dropzone.UPLOADING
    this.source.dropZone.emit("processing", this.file)
  }

  emitDropzoneError(error) {
    this.file.status = Dropzone.ERROR
    this.source.dropZone.emit("error", this.file, error)
    this.source.dropZone.emit("complete", this.file)
  }

  emitDropzoneSuccess() {
    this.file.status = Dropzone.SUCCESS
    this.source.dropZone.emit("success", this.file)
    this.source.dropZone.emit("complete", this.file)
  }
}

// Top level...
function createDirectUploadController(source, file) {
  return new DirectUploadController(source, file)
}

function createDirectUpload(file, url, controller) {
  return new DirectUpload(file, url, controller)
}

function createDropZone(controller) {
  return new Dropzone(controller.element, {
    url: controller.url,
    headers: controller.headers,
    maxFiles: controller.maxFilesValue,
    maxFilesize: controller.maxFileSizeValue,
    addRemoveLinks: controller.addRemoveLinksValue,
    thumbnailWidth: controller.thumbnailWidthValue,
    thumbnailHeight: controller.thumbnailHeightValue,
    thumbnailMethod: controller.thumbnailMethodValue,
    acceptedFiles: controller.inputTarget.accept,
    autoQueue: false
  })
}

// function createHiddenInput(fileInput: HTMLInputElement, value: any) {
//   const input = document.createElement("input")
//   input.type = "hidden"
//   input.name = fileInput.name
//   input.value = value
//   insertAfter(input, fileInput)
//   return input
// }

function addExistingFile(dropzone, existingFile: { name: string, size: number, blobId: string, thumbnailUrl?: string }) {
  let mockFile = {
    name: existingFile.name,
    size: existingFile.size,
    accepted: null,
    status: null,
    previewElement: null
  }
  const { thumbnailUrl } = existingFile

  let callback = null
  let crossOrigin = null // Added to the `img` tag for crossOrigin handling
  let resizeThumbnail = false // Tells Dropzone whether it should resize the image first

  // Workaround zone until https://github.com/dropzone/dropzone/pull/2003 merged
  callback = () => {
    mockFile.previewElement?.classList?.add("dz-processing")
  }
  mockFile.accepted = true
  mockFile.status = Dropzone.SUCCESS
  dropzone.files.push(mockFile)
  dropzone._updateMaxFilesReachedClass()
  //endzone

  dropzone.displayExistingFile(mockFile, thumbnailUrl, callback, crossOrigin, resizeThumbnail)
}

Dropzone.autoDiscover = false

