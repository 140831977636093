import $ from 'jquery';

$(document).ajaxError(function(e, xhr, settings) {
  if (xhr.status == 401) {
    window.location.href = "/users/sign_in"
  }

  if (xhr.status == 404) {
    window.location.href = "/page-not-found"
  }
});

// document.body.addEventListener('ajax:error', function (event) {
//   var detail = event.detail;
//   var response = detail[0], status = detail[1], xhr = detail[2];
//   console.log(detail)
//   if (xhr.status == 401) {
//     location.reload()
//   }
// })

function getStripeKey() {
  let script = document.querySelector(
    "script[data-stripejs]",
  )
  return script?.getAttribute("data-stripejs")
}

export function initStripe() {
  let key = getStripeKey()
  if (!key) {
    console.warn("No Stripe key found!")
    return
  }
  Stripe(key)
}
