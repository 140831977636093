import $ from 'jquery';
//import 'select2/dist/css/select2.css';
import 'select2';

document.addEventListener("turbo:load", () => {

  $('.select2:not(.select2-hidden-accessible)').select2({
    allowClear: true,
    placeholder: "",
    width: '100%',
  })

  $(".select2-suggest:not(.select2-hidden-accessible)").select2({
    width: '100%',
    minimumInputLength: 3,
    allowClear: true,
    ajax: {
      url: '/search/suggest.json',
      dataType: "json",
      cache: true,
      delay: 100,
      data: function(params) {
        return { term: params.term, type: $(this).data('suggestion-type') }
      },
      processResults: function(data) {
        return { results: data }
      }
    }
  })

  $(".select2-suggest-multi:not(.select2-hidden-accessible)").select2({
    width: '100%',
    minimumInputLength: 3,
    multiple: true,
    ajax: {
      url: "/search/suggest.json",
      dataType: "json",
      cache: true,
      delay: 100,
      data: function(params) {
        return { term: params.term, type: $(this).data('suggestion-type') }
      },
      processResults: function(data) {
        return { results: data }
      }
    }
  })
});
