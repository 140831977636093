import { Controller } from "@hotwired/stimulus"
import { Turbo } from "@hotwired/turbo-rails"

export default class extends Controller {
  static targets = ["scrollArea", "pagination"]

  declare readonly hasScrollAreaTarget: boolean
  declare readonly scrollAreaTarget: HTMLElement
  declare readonly scrollAreaTargets: HTMLElement[]

  declare readonly hasPaginationTarget: boolean
  declare readonly paginationTarget: HTMLElement
  declare readonly paginationTargets: HTMLElement[]

  connect() {
    this.createObserver()
  }

  createObserver() {
    const observer = new IntersectionObserver(
      entries => this.handleIntersect(entries),
      {
        // https://github.com/w3c/IntersectionObserver/issues/124#issuecomment-476026505
        threshold: [0, 1.0],
      }
    )
    observer.observe(this.scrollAreaTarget)
  }

  handleIntersect(entries) {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        this.showLoadingIndicator()
        this.loadMore()
      }
    })
  }

  showLoadingIndicator() {
    this.scrollAreaTarget.classList.remove("invisible")
  }

  hideLoadingIndicator() {
    this.scrollAreaTarget.classList.add("invisible")
  }

  loadMore() {
    const next = this.paginationTarget.querySelector("a[rel=next]") as HTMLAnchorElement
    if (!next) {
      this.hideLoadingIndicator()
      return
    }

    const href = next.href

    backgroundFetchTurboStream(href).then(_ => this.hideLoadingIndicator())
  }
}

async function backgroundFetchTurboStream(href: string) {
  return fetch(href, {
    headers: {
      Accept: "text/vnd.turbo-stream.html",
    },
  }).then(r => r.text())
    .then(html => Turbo.renderStreamMessage(html))
    .then(_ => history.replaceState(history.state, "", href))
}

