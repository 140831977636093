// Entry point for the build script in your package.json
//
import "./startup"
import "@rails/activestorage";
import "@hotwired/turbo";

import "./search";
import "./memberships";
import "./text_editor";

import { Application } from "@hotwired/stimulus";

import NestedForm from "stimulus-rails-nested-form";
import {
  Alert,
  Dropdown,
  Modal,
  Popover,
  Slideover,
  Tabs,
  Toggle,
} from "tailwindcss-stimulus-components";

import DropzoneController from "./controllers/dropzone_controller"
import GridController from "./controllers/grid_controller"
import MasonryController from "./controllers/masonry_controller"
import InfiniteScrollController from "./controllers/infinite_scroll_controller"
import TogglerController from "./controllers/toggler_controller"

const application = Application.start();

application.register("nested-form", NestedForm)
application.register("alert", Alert);
application.register("dropdown", Dropdown);
application.register("modal", Modal);
application.register("tabs", Tabs);
application.register("popover", Popover);
application.register("toggle", Toggle);
application.register("slideover", Slideover);

application.register("dropzone", DropzoneController)
application.register("grid", GridController)
application.register("masonry", MasonryController)
application.register("infinite-scroll", InfiniteScrollController)
application.register("toggler", TogglerController)

