import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["element"]
  static classes = ["toggle"]

  toggleClass: "toggle"

  declare readonly hasElementTarget: boolean
  declare readonly elementTarget: HTMLInputElement
  declare readonly elementTargets: HTMLInputElement[]

  toggle() {
    if (this.elementTarget.classList.contains(this.toggleClass)) {
      this.turnOff()
    } else {
      this.turnOn()
    }
  }

  turnOff() {
    this.elementTarget.classList.remove(this.toggleClass)
  }

  turnOn() {
    this.elementTarget.classList.add(this.toggleClass)
  }
}
