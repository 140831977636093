import Masonry from "masonry-layout"
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["gridItem"]
  static values = {
    columnWidth: { type: String, default: ".grid-sizer" },
    containerStyle: { type: Object, default: {} },
    fitWidth: { type: Boolean, default: true }
  }
  grid: any

  declare containerStyleValue: string
  declare readonly hasContainerStyleValue: boolean
  declare columnWidthValue: string
  declare readonly hasColumnWidthValue: boolean
  declare fitWidthValue: string
  declare readonly hasFitWidthValue: boolean

  declare readonly hasGridItemTarget: boolean
  declare readonly gridItemTarget: HTMLElement
  declare readonly gridItemTargets: HTMLElement[]

  connect() {
    this.grid = new Masonry(this.element, {
      itemSelector: "[data-masonry-target=gridItem]",
      columnWidth: numberOrString(this.columnWidthValue),
      containerStyle: this.containerStyleValue,
      fitWidth: this.fitWidthValue,

    })
  }

  gridItemTargetConnected(element: HTMLElement): void {
    if (!this.grid)
      return

    this.grid.appended(element)
  }

  gridItemTargetDisconnected(element: HTMLElement): void {
    if (!this.grid)
      return

    this.grid.remove(element)
  }

  reflow(): void {
    if (!this.grid)
      return

    console.log("Reflowing")
    this.grid.layout()
  }

}

function numberOrString(value: string): number | string {
  let maybeNumber = parseInt(value)
  if (!Number.isNaN(maybeNumber))
    return maybeNumber

  return value
}
