import Packery from "packery"
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  declare readonly percentPositionValue: Number
  static targets = ["item"]
  static values = {
    percentPosition: { type: Boolean, default: true }
  }

  grid: any

  connect(): void {
    this.grid = new Packery(this.element, {
      itemSelector: "[data-grid-target=item]",
      percentPosition: this.percentPositionValue
    })

    document.addEventListener("turbo:load", () => {
      this.grid.layout()

      let x = this.grid
      setTimeout(function() {
        x.layout()
      }, 3000);
    });
  }

  disconnect(): void {
    this.grid.destroy()
  }

  itemTargetConnected(element: HTMLElement): void {
    element.setAttribute("style", "")
    if (!this.grid)
      return

    this.grid.appended(element)
    this.grid.layout()
  }

  itemTargetDisconnected(element: HTMLElement): void {
    if (!this.grid)
      return

    this.grid.remove(element)
  }

  reflow(): void {
    if (!this.grid)
      return

    console.log("Reflowing")
    this.grid.layout()
  }

}

function numberOrString(value: string): number | string {
  let maybeNumber = parseInt(value)
  if (!Number.isNaN(maybeNumber))
    return maybeNumber

  return value
}
