
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import $ from 'jquery';
import Rails from "@rails/ujs";

Rails.start()

const touchOrClick = $.support.touch ? "tap" : "click";
const ready = () => {
  $("#mobile-menu").removeClass("hidden");

  //Profile dropdown menu
  $('.drop-down-btn').on("click", function() {
    $('.drop-down-menu').toggleClass('hidden')
  })

  //Mobile Menu
  $('#mobile-menu-btn').on("click", function() {
    // PRIMARY NAVIGATION
    $("#body-overlay").removeClass("hidden");
    $('#mobile-menu').removeClass('translate-x-full');
    $('#mobile-menu').addClass('translate-x-0');
    $('body').addClass('overflow-hidden');
  })

  $("#closeButton").on("click", function() {
    $("#body-overlay").addClass("hidden");
    $('body').removeClass('overflow-hidden');
    $('#mobile-menu').removeClass('translate-x-0');
    $('#mobile-menu').addClass('translate-x-full');
  })

  //Sticky Nav
  $(window).scroll(function() {
    var windowTop = $('html').scrollTop();

    if (windowTop && $(window).width() > 768) {
      $('.scale-down').addClass('scale-75');
    } else {
      $('.scale-down').removeClass('scale-75');
    }
  }).scroll(); //invoke scroll-handler on page-load

  //Footer

  $(".footer").on(touchOrClick, function() {
    $(this).toggleClass('toggled-up');
  });


  $('.footer').hover(function() {
    $(this).addClass('peek-up');
  }, function() {
    $(this).removeClass('peek-up');
  });


  // Comment Focus at #comment url
  var hash = $(location).attr('hash');
  if (hash == '#comment') {
    $(".comment-body:first").focus()
  }

};

document.addEventListener("turbo:load", ready);



